import { modalAtom } from "../../lib/atom";
import { useAtom } from "jotai";
import React from "react";

import ReactGA from "react-ga4";

interface ExtraClasses {
  extraClasses: string;
}

const Button: React.FC<ExtraClasses> = ({ extraClasses }) => {
  const [, setModalState] = useAtom<boolean>(modalAtom);

  const handleButtonCLick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setModalState(true);

    ReactGA.event({
      category: "Button Click",
      action: "Join waitlist button clicked",
      label: "User clicked to open modal to join waitlist",
    });
  };

  return (
    <>
      <button
        onClick={handleButtonCLick}
        className={`bg-[#f6891f] text-xs text-white rounded-lg px-3 p-2 ${extraClasses}`}
      >
        Join the waitlist
      </button>
    </>
  );
};

export default Button;
