import { useAtom } from "jotai";
import Iphone15 from "../images/iPhone 15 Pro.png";
import NewsLetterForm from "./Includes/NewsLetterForm";
import { languageAtom } from "../lib/atom";

const Hero = () => {
  const [lang, setLang] = useAtom<string>(languageAtom);

  type ObjectType = {
    English: string;
    Pidgin: string;
  };

  const heroText: ObjectType = {
    English: `Lonux is changing the game of buying & selling. We are building a
    technology that bridges traditional commerce with electronic
    commerce (E-commerce). We make it easy to find what you need, fast
    and reliable, close to you and anywhere in the world. Stop wasting
    time, increase sales and boost visibility. Lonux gets you found by
    customers in need of exactly what you offer. Join the exclusive
    waitlist for business owners.`,
    Pidgin: `Lonux wan change the way we dey buy and sell. 
    We dey build technology wey go join our normal market 
    with online market. We wan make am easy for you to find wetin you need, 
    sharp sharp and e go dey reliable, near you and anywhere for the world. 
    Stop to dey waste time, increase market and make people see you well-well. 
    Lonux go make sure say people were dey find wetin you dey sell see you. 
    Come join our exclusive waitlist for business owners.`,
  };

  return (
    <div className="mx-auto max-w-5xl flex justify-between items-center pb-16 pt-24">
      <div className="flex-1 w-5/6 md:w-full text-center">
        <h2 className="font-extrabold text-3xl md:text-4xl mb-5">
          Buy fast, sell more
        </h2>
        <div className="md:text-start text-center mx-10 lg:mx-0">
          <p className="text-start text-base">
            {heroText[lang as keyof ObjectType]}
          </p>
        </div>
        <NewsLetterForm extraClasses={"w-5/6 md:w-4/6 mx-auto mt-10"} />
      </div>
      <div className="flex-1 text-center md:block hidden">
        <img className="hidden md:block" src={Iphone15} alt="Image" />
      </div>
    </div>
  );
};

export default Hero;
