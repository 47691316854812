import WhyLonux from "./Components/WhyLonux";
import FAQs from "./Components/FAQs";
import HowLonuxWorks from "./Components/HowLonuxWorks";
import Download from "./Components/Download";
import FooterSection from "./Components/FooterSection";
import HeaderWrapper from "./Components/HeaderWrapper";
import JoinForm from "./Components/Includes/JoinForm";

const WaitList = () => {
  return (
    <div className="relative font-ClashDisplay" id="nav">
      <HeaderWrapper />
      <WhyLonux />
      <FAQs />
      <HowLonuxWorks />
      <Download />
      <FooterSection />
      <JoinForm />
    </div>
  );
};

export default WaitList;
