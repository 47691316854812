import React, { useState } from "react";

import logo from "../images/logo.png";
import { FaAngleDown } from "react-icons/fa";
import Button from "./Includes/Button";
import { useAtom } from "jotai";
import { languageAtom } from "../lib/atom";

const Navbar = () => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useAtom<string>(languageAtom);

  const languages = ["English", "Pidgin"];

  return (
    <nav className="mx-auto max-w-5xl py-4 flex justify-between items-center">
      <div className="mx-2 sm:mx-5 lg:mx-0">
        <img src={logo} alt="Lonux Logo" className="w-28" />
      </div>
      <div className="flex items-center me-2 lg:mx-0">
        {/* dropdown  */}
        <div className="text-[#f6891f] relative md:me-32 me-5">
          <div
            className="bg-transparent inline-flex focus:outline-none cursor-pointer"
            onClick={() => setOpenDropDown(!openDropDown)}
          >
            <span className="mx-3">{selectedLanguage}</span>{" "}
            <FaAngleDown className="mt-2" />
          </div>
          <div
            className={`absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-10 ${
              openDropDown ? "" : "hidden"
            }`}
          >
            {languages.map((lang, index) => (
              <a
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedLanguage(lang);
                  setOpenDropDown(!openDropDown);
                }}
                href="#"
                className="block px-4 py-2 text-gray-800 hover:rounded-lg hover:bg-gray-200"
              >
                {lang}
              </a>
            ))}
          </div>
        </div>
        <Button extraClasses="" />
      </div>
    </nav>
  );
};

export default Navbar;
