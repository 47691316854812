import Hero from "./Hero";
import Navbar from "./Navbar";

const HeaderWrapper = () => {
  return (
    <div className="bg-gradient-to-b  from-bgGradient-start via-bgGradient-middle to-bgGradient-end">
      <Navbar />
      <Hero />
    </div>
  );
};

export default HeaderWrapper;
