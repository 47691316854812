import { useAtom } from "jotai";
import Button from "./Button";
import { emailAtom } from "../../lib/atom";

type ClassesProps = {
  extraClasses: String;
};

const NewsLetterForm = ({ extraClasses }: ClassesProps) => {
  const [email, setEmail] = useAtom<string>(emailAtom);
  return (
    <>
      <form onSubmit={(e) => e.preventDefault()} className={`${extraClasses}`}>
        <div className="relative border border-gray-500 rounded-md">
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full py-2 pl-10 pr-2 bg-transparent rounded-md border border-gray-300 focus:outline-none focus:border-gray-500"
            placeholder="Email Address"
          />

          <Button extraClasses="absolute inset-y-0 right-0 m-0.5" />
        </div>
      </form>
    </>
  );
};

export default NewsLetterForm;
