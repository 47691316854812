import React from "react";

type WhyProps = {
  number: String;
  heading: String;
  body: String;
};

const WhyLonuxSingle = ({ number, heading, body }: WhyProps) => {
  return (
    <div className="md:max-w-60 max-w-full flex md:block justify-around my-5">
      <h1 className="text-3xl p-5 flex-1 w-32 md:w-14 h-14 mt-9 rounded-lg py-2 font-bold bg-[#f7dec7]">
        {number}
      </h1>
      <div className="ms-5 md:ms-0">
        <h6 className="mt-8 font-semibold text-start">{heading}</h6>
        <p className="mt-4 text-start">{body}</p>
      </div>
    </div>
  );
};

export default WhyLonuxSingle;
