import DownloadImage from "../images/Download.png";

const Download = () => {
  return (
    <div className="mx-auto max-w-full p-4 flex justify-center items-center pb-16 bg-bgGradient-end">
      <img
        className="cursor-pointer"
        src={DownloadImage}
        alt="Download Lonux"
      />
    </div>
  );
};

export default Download;
