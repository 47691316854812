import React, { useEffect, useState } from "react";

type FAQProps = {
  question: String;
  answers: Array<String>;
  type: String;
};

const FAQSingle = ({ question, answers, type }: FAQProps) => {
  const [showAnswer, setShowAnswer] = useState<boolean>(false);

  return (
    <div className="mb-2" id="faq">
      <div
        className="border border-white border-l-[#f6891f] border-l-4 rounded-md px-4 py-2 cursor-pointer flex justify-between items-center bg-white"
        onClick={() => setShowAnswer(!showAnswer)}
      >
        <h3 className="font-normal text-start">{question}</h3>
        <svg
          className={`w-4 h-4 fill-current text-gray-500 transform transition-transform duration-300 ${
            showAnswer ? "rotate-180" : ""
          } `}
          viewBox="0 0 20 20"
        >
          <path d="M6 9l4 4 4-4"></path>
        </svg>
      </div>
      <div
        className={`px-4 rounded-b-lg py-2 text-start ${
          showAnswer ? "" : "hidden"
        } bg-[#f7dec7]`}
      >
        {type === "ul" ? (
          <ul className="list-disc ps-3">
            {answers.map((answer, index) => (
              <li key={index}>{answer}</li>
            ))}
          </ul>
        ) : (
          <>{answers[0]}</>
        )}
      </div>
    </div>
  );
};

export default FAQSingle;
