import { useAtom } from "jotai";
import Button from "./Includes/Button";
import { languageAtom } from "../lib/atom";

const HowLonuxWorks = () => {
  const [lang] = useAtom<string>(languageAtom);

  const introText: object = {
    English: `Lonux is creating a technology that will simplify buying and selling
    by making commerce(offline and online) absolute and organized.
    Here's the breakdown for both sides:`,
    Pidgin: `Lonux dey create technology were go make buying and selling easy and we go first 
    connect normal market with online market so that buying and selling go dey more organized and easy. 
    See the breakdown for both buyer and seller`,
  };

  const businessesTexts: object = {
    English: [
      "Effortless Listing: Sign up and list your products or services on the user-friendly Lonux platform.",
      "Accurate Inventory Management: Lonux helps you track inventory levels, ensuring you have what customers need.",
      "Wider Customer Reach: Lonux connects you with a vast pool of potential customers actively in need of your offerings.",
      "Streamlined Sales: Receive and manage orders efficiently, potentially including features like secure payment processing.",
      "Team Management: Assign tasks, track staff performance, and collaborate within the Lonux platform.",
    ],
    Pidgin: [
      "Easy Listing: Business owner go create account come list all their products for our easy to use platform",
      "Sharp Inventory Management: Lonux go dey help you arrange your inventory and go make sure say you get wetin businesses need.",
      "Plenty Customers: Lonux go connect you with plenty customers where dey find wetin you dey sell",
      "Streamlined Sales: You go fit receive orders and you go also fit manage the order and also receive payment for online.",
    ],
  };
  const businessesTextsArray = businessesTexts[
    lang as keyof object
  ] as String[];

  const customersTexts: object = {
    English: [
      "Seamless Search: Find the products or services you need with ease using Lonux's intuitive search features.",
      "Accurate Results: Lonux displays businesses with the exact products you're looking for, eliminating wasted trips.",
      "Wider Selection: Access a vast marketplace of businesses, increasing the chances of finding what you need.",
      "Convenient Shopping: Browse, compare, and potentially purchase directly through the Lonux platform.",
    ],
    Pidgin: [
      "Better Searching: You go fit find wetin you wan buy or hand work person were you want with Lonux search were dey very easy to use.",
      "Confam Results: Lonux go show you as e dey. We go show you exactly wetin you dey find and where them dey sell am so that you no go go pass one shop.",
      "Plenty Options: Plenty businesses dey Lonux so you get the chance to select anyone were your mind dey.",
      "Easy Shopping: You go just carry your phone, browse, choose anyone you like and you fit buy am instantly self.",
    ],
  };
  const customersTextsArray = customersTexts[lang as keyof object] as String[];

  return (
    <div className="mx-auto max-w-full p-4 flex justify-center items-center pb-16 bg-bgGradient-end">
      <div className="text-center w-4/6">
        <h2 className="md:text-4xl text-3xl text-[#f6891f] font-extrabold pb-12">
          How Lonux Works
        </h2>
        <div className="text-start">
          <p>{introText[lang as keyof object]}</p>
          <p className="mt-4 font-bold">
            {lang === "English" ? "For Businesses:" : "For Sellers"}
          </p>
          <ul className="list-disc ps-4">
            {businessesTextsArray.map((business: String, index: number) => (
              <li key={index}>{business}</li>
            ))}
          </ul>
          <p className="mt-4 font-bold">
            {lang === "English" ? "For Customers:" : "For Buyers"}
          </p>
          <ul className="list-disc ps-4">
            {customersTextsArray.map((customer: String, index: number) => (
              <li key={index}>{customer}</li>
            ))}
          </ul>
        </div>
        <div className="mt-10">
          <Button extraClasses="" />
        </div>
      </div>
    </div>
  );
};

export default HowLonuxWorks;
