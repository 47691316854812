import React from "react";
import FAQSingle from "./Includes/FAQSingle";
import { useAtom } from "jotai";
import { languageAtom } from "../lib/atom";

const FAQs = () => {
  const [lang] = useAtom<string>(languageAtom);

  const faqTexts: object = {
    English: {
      whatIsLonux: {
        q: "What is Lonux?",
        a: [
          "Lonux is a digital technology designed to simplify buying and selling by first, synching both offline and online commerce thereby making buying and selling easy and absolute. We connect businesses with customers in need of their products and services, eliminating the hassle of trial and error and ensuring customers and businesses get satisfaction in their daily business activities.",
        ],
      },
      benefits: {
        q: "How does Lonux benefit businesses?",
        a: [
          "Increased Sales: Reach a wider audience and showcase your products to actively searching customers.",
          "Reduced Stress: Streamline inventory management to avoid overstocking or understocking.",
          "Faster Sales: Eliminate the frustration of customers bouncing between stores, leading to quicker purchases.",
          "Effortless Team Management: Assign staff, track performance, and collaborate seamlessly within Lonux.",
          "Wider Customer Reach: Gain exposure to customers who might not have found your business otherwise.",
        ],
      },
      whyJoinWaitlist: {
        q: "Why should I join the Lonux waitlist?",
        a: [
          "Be among the first to experience the future of buying and selling! Lonux offers a seamless experience for both businesses and customers. Joining the waitlist ensures you receive exclusive updates and early access to the platform.",
        ],
      },
      isThereaFee: {
        q: "Is there a fee to use Lonux?",
        a: [
          "We haven't announced pricing details yet. However, joining the waitlist keeps you informed about any future pricing plans.",
        ],
      },
      whoCanJoin: {
        q: "Who can join the Lonux waitlist?",
        a: [
          "Currently, the waitlist is open to business owners only. We will open it to customers when we are closer to launch.",
        ],
      },
      whatHappensAfterIJoin: {
        q: "What happens after I join the waitlist?",
        a: [
          "You'll receive updates about Lonux's development, launch dates, exclusive offers, and valuable insights for running your business.",
        ],
      },
    },
    Pidgin: {
      whatIsLonux: {
        q: "Wetin be Lonux ?",
        a: [
          "Lonux na digital technology were go make buying and selling easy. We go first connect normal market with online market that one go come make the whold thing more organized and clean. Then we go come dey connect businesses with customers were dey find their markets or hand work, that one now go come reomve all this 'Mama chioma no get soap' wahala. Everybody go just dey happy for their doings.",
        ],
      },
      benefits: {
        q: "How Lonux dey benefit businesses ?",
        a: [
          "Plenty Market: Lonux go show all the plenty people were dey find wetin you dey sell your business.",
          "Reduce Stress: We go help you reduce all the stress of counting and calculating your goods.",
          "Fast Market: Since we go make sure say customers dey go only where them go get wetin them want, sales go dey dey faster",
          "Easy Workers Management:  You go fit assing staff, give them work, track how them dey do and work with them easily all insode the Lonux platform.",
          "Plenty Customer: Customers were dey find wetin you dey sell go fit find you sharparly.",
        ],
      },
      whyJoinWaitlist: {
        q: "Why make I join this Lonux waitlist ?",
        a: [
          "You go dey among the first people were go experience the future of market were Lonux the create. We dey offer smooth experience between businesses and customers and joinging this waitlist go ensure say you receive eary update and access to the platform",
        ],
      },
      isThereaFee: {
        q: "Fee dey to use Lonux ?",
        a: [
          "Lonux never announce any details like that yet but if you join this waitlist now, we go late tell you about am.",
        ],
      },
      whoCanJoin: {
        q: "Who fit join this waitlist ?",
        a: [
          "Right now ba, the waitlist dey open to people were get business. We go open am for customers when we don close to launch",
        ],
      },
      whatHappensAfterIJoin: {
        q: "Wetin go happen after I join ?",
        a: [
          "You go dey receive updates about Lonux development, launch dates, offers and better insights on how to do package your business.",
        ],
      },
    },
  };

  const faqs = [
    {
      question: faqTexts[lang as keyof object]["whatIsLonux"]["q"],
      type: "p",
      answer: faqTexts[lang as keyof object]["whatIsLonux"]["a"],
    },
    {
      question: faqTexts[lang as keyof object]["benefits"]["q"],
      type: "ul",
      answer: faqTexts[lang as keyof object]["benefits"]["a"],
    },
    {
      question: faqTexts[lang as keyof object]["whyJoinWaitlist"]["q"],
      type: "p",
      answer: faqTexts[lang as keyof object]["whyJoinWaitlist"]["a"],
    },
    {
      question: faqTexts[lang as keyof object]["isThereaFee"]["q"],
      type: "p",
      answer: faqTexts[lang as keyof object]["isThereaFee"]["a"],
    },
    {
      question: faqTexts[lang as keyof object]["whoCanJoin"]["q"],
      type: "p",
      answer: faqTexts[lang as keyof object]["whoCanJoin"]["a"],
    },
    {
      question: faqTexts[lang as keyof object]["whatHappensAfterIJoin"]["q"],
      type: "p",
      answer: faqTexts[lang as keyof object]["whatHappensAfterIJoin"]["a"],
    },
  ];

  return (
    <div className="mx-auto max-w-full p-4 flex justify-center items-center pb-16 bg-bgGradient-end">
      <div className="text-center md:w-4/6 w-5/6">
        <h1 className="md:text-4xl text-3xl text-[#f6891f] font-extrabold pb-12">
          {lang === "English"
            ? "Frequently Asked Questions"
            : "Wetin People Dey Ask"}
        </h1>

        <div className="w-full">
          {faqs.map((faq, index) => (
            <FAQSingle
              question={faq.question}
              answers={faq.answer}
              type={faq.type}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
