import { useAtom } from "jotai";
import WhyLonuxPNG from "../images/whylonux.png";
import Button from "./Includes/Button";
import WhyLonuxSingle from "./Includes/WhyLonuxSingle";
import { languageAtom } from "../lib/atom";

const WhyLonux = () => {
  const [lang] = useAtom<string>(languageAtom);

  const whylonuxTexts: object = {
    English: {
      overview:
        "Quickly get a detailed summary of your business(shops, sales, inventory, finances and staff). Giving you full insight of your business and helping you make good business decisions.",
      customers:
        "Reach a wider audience and showcase your products to customers actively searching for them.",
      manageShop:
        "Say goodbye to stockouts and wasted time! Say hello to happy customers who find exactly what they need, every time.",
      staff:
        "Lonux empowers you to  assign staff to manage your online store with ease, delegate tasks, track performance, and collaborate seamlessly  - all within the Lonux platform.",
    },
    Pidgin: {
      overview:
        "We go give you sharp-sharp and complete summary of your business like your shops, markets, inventory, money and your workers). This go give you complete inside about your business and e go help you make correct and strong business decisions.",
      customers:
        "We go help you reach plenty people and show wetin you dey sell to customers wey dey find am.",
      manageShop:
        "No worry, we go help you handle all the haeadache of markets were don finish, so that customer no go come your shop no come find wetin them dey find. Your own na to just dey attend to happy customers were Lonux go bring for you",
      staff:
        "See ba, Lonux go even allow you add your workers for the platform and you go fit give them different roles and even dey monitor wetin them dey do. This go help collaboration between you and them and go help you relax while your business dey boom",
    },
  };

  return (
    <div className="mx-auto max-w-6xl sm:max-w-full p-4 flex justify-center items-center bg-bgGradient-end pb-16">
      <section className="text-center lg:mx-36 sm:mx-8 sm:w-full w-5/6 lg:w-4/6 justify-center">
        <h1 className="md:text-4xl text-3xl text-[#f6891f] font-extrabold mb-6 md:mb-12">
          Why Lonux
        </h1>
        <div className="md:flex justify-between">
          <div className="md:flex md:flex-col">
            <WhyLonuxSingle
              number={"1"}
              heading={"Shop Overview"}
              body={whylonuxTexts[lang as keyof object]["overview"]}
            />

            <WhyLonuxSingle
              number={"3"}
              heading={"Get Customers"}
              body={whylonuxTexts[lang as keyof object]["customers"]}
            />
          </div>

          <div className="md:flex items-center hidden">
            <img
              src={WhyLonuxPNG}
              alt="Image"
              className="lg:h-4/5 h-full mx-auto"
            />
          </div>

          <div className="flex flex-col justify-between">
            <WhyLonuxSingle
              number={"2"}
              heading={"Manage Shops"}
              body={whylonuxTexts[lang as keyof object]["manageShop"]}
            />
            <WhyLonuxSingle
              number={"4"}
              heading={"Assign Staff"}
              body={whylonuxTexts[lang as keyof object]["staff"]}
            />
          </div>
        </div>

        <Button extraClasses="mt-11 text-center" />
      </section>
    </div>
  );
};

export default WhyLonux;
