import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import logo from "../images/logo.png";
import NewsLetterForm from "./Includes/NewsLetterForm";

const FooterSection = () => {
  return (
    <div className="p-4 w-full pb-12 bg-[#f7dec7]">
      <div className="mx-auto max-w-7xl md:max-w-6xl flex flex-col md:flex-row justify-center items-center">
        <div className="md:w-3/5 w-5/6 mt-8 flex md:justify-start justify-center items-center">
          <img src={logo} alt="Lonux Logo" className="w-28 md:ms-32" />
        </div>
        <div className="md:w-3/5 w-5/6 mt-8 text-center md:text-start">
          <h2 className="text-1xl md:text-2xl text-[#f6891f]">
            Join Our Community
          </h2>
          <div className="flex justify-center items-center md:justify-start gap-4 text-[#f6891f] mt-6">
            <a target="blank" href="https://x.com/lonuxglobal">
              <span className="text-2xl">
                <FaTwitter />
              </span>
            </a>
            <a target="blank" href="http://instagram.com/lonuxglobal">
              <span className="text-2xl">
                <FaInstagram />
              </span>
            </a>
            <a target="blank" href="http://facebook.com/lonuxglobal">
              <span className="text-2xl">
                <FaFacebook />
              </span>
            </a>
            <a target="blank" href="http://linkedin.com/company/lonux">
              <span className="text-2xl">
                <FaLinkedin />
              </span>
            </a>
          </div>
          <div>
            <NewsLetterForm extraClasses={"md:w-3/6 w-full mt-10 md:mt-6"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
